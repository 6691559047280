import useConfigurationsStore from '~/stores/useConfigurationsStore'

export const getConfiguration = (key: string) => {
  const { getConfiguration } = useConfigurationsStore()

  return getConfiguration(key)
}

export const getTextConfiguration = (key: string) => {
  return getConfiguration(`text.${key}`)
}
