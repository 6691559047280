import { useFancyHeaderStore } from '~/stores/useFancyHeaderStore'

export function useFancyHeader() {
  const fancyHeaderStore = useFancyHeaderStore()

  onMounted(() => {
    fancyHeaderStore.setValue(true)
  })

  onBeforeUnmount(() => {
    fancyHeaderStore.setValue(false)
  })
}
