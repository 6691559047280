import getEstateMapRouteName from './getEstateMapRouteName'
import getEstateBaseRouteName from './getEstateBaseRouteName'
import type { LocationQueryRaw } from '#vue-router'
import type { EstateType } from '~/types/EstateType'

export default function getEstateListingLink({
  estateType,
  listingType = 'list',
  query,
  hash,
}: {
  estateType: EstateType
  listingType?: 'list' | 'map'
  query?: LocationQueryRaw
  hash?: string
}) {
  return useLocaleLink({
    name:
      listingType === 'map'
        ? getEstateMapRouteName(estateType)
        : getEstateBaseRouteName(estateType),
    query,
    hash,
  })
}
