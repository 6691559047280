export function useModalState({ modalId }: { modalId: string }) {
  const isOpened = ref(false)
  const router = useRouter()
  const isMounted = ref(false)

  onMounted(async () => {
    const route = useRoute()
    if (route.query.modal === modalId) {
      isOpened.value = true
    }

    await nextTick()
    isMounted.value = true
  })

  function open() {
    // ? If you have slow internet connection and open modal before init, there's error in overlay: not having ref element
    if (!isMounted.value) return

    isOpened.value = true
  }

  function close() {
    if (!isMounted.value) return

    isOpened.value = false
  }

  function closeOnEscape(e: KeyboardEvent) {
    if (e.key !== 'Escape') return

    close()
  }

  function onOpen() {
    const route = useRoute()
    router.push({
      path: route.path,
      query: {
        ...route.query,
        modal: modalId,
      },
    })

    document.body.style.overflow = 'hidden'
    document.addEventListener('keydown', closeOnEscape)
  }

  function onClose() {
    const route = useRoute()

    if (route.query.modal === modalId) {
      if (window.history.length > 2) {
        router.back()
      } else {
        const query = { ...route.query }
        delete query.modal

        router.replace({ ...route, query })
      }
    }

    document.body.style.overflow = ''
    document.removeEventListener('keydown', closeOnEscape)
  }

  watch(
    () => router.currentRoute.value.query.modal,
    (newValue, oldValue) => {
      if (newValue === modalId) {
        open()
      }

      if (oldValue === modalId) {
        close()
      }
    }
  )

  return {
    id: modalId,
    isOpened,
    open,
    close,
    onOpen,
    onClose,
  }
}
