export function usePrivacyPolicyHref() {
  const { locale } = useI18n()

  const documents = {
    sk: '/docs/ido-pre-webovy-kontaktny-formular.pdf',
    en: '/docs/ido-for-web-contact-form-en.pdf',
  } as Record<string, string>

  return documents[locale.value] ?? documents.sk
}
