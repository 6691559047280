<script setup>
import ErrorMain from './components/errorPage/ErrorMain.vue'

useFancyHeader()

const error = useError()

// // TODO: show this only with debug mode
try {
  console.error(error?.value?.message)
  console.error(error)
} catch (error) {
  console.error(error)
}

useHead({
  title: error?.value?.statusCode?.toString() || 'Error',
})
</script>

<template>
  <div id="app-root">
    <NuxtLayout>
      <ErrorMain />
    </NuxtLayout>
  </div>
</template>

<style lang="scss" scoped>
@use '@inkline/inkline/css/mixins' as *;

.c-error {
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  text-align: center;
  padding: 50px 0;

  &__code {
    line-height: 1;
    font-size: 120px;
    font-weight: 600;
    margin: 0 0 24px;
  }

  &__message {
    font-size: 20px;
    font-weight: 500;
    margin: 0;
    color: var(--color-red);
    word-break: break-word;
  }
}
</style>
