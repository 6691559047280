import { preparedRedirects } from '#nuxt-prepare'

export default defineNuxtRouteMiddleware((to) => {
  const toPathTrimmed = to.path.replace(/\/$/, '')

  if (toPathTrimmed in preparedRedirects) {
    const redirectData =
      preparedRedirects[toPathTrimmed as keyof typeof preparedRedirects]

    let locale = 'sk'
    if (toPathTrimmed.startsWith('/en/')) {
      locale = 'en'
    }

    return navigateTo(useLocaleLink(redirectData, locale), {
      redirectCode: 301,
    })
  }
})
