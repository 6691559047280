export function formatNumber(
  number: number | string | undefined | null,
  options: Intl.NumberFormatOptions = {}
) {
  number = Number(number)

  if (isNaN(number)) {
    return number.toString()
  }

  if (options.minimumFractionDigits === undefined) {
    options.minimumFractionDigits = 0
  }

  if (options.maximumFractionDigits === undefined) {
    options.maximumFractionDigits = 2
  }

  try {
    // TODO: Use locale based on chosen language
    return new Intl.NumberFormat('sk-SK', options).format(number)
  } catch (error) {
    console.error(number, options)
    console.error(error)

    return number.toString()
  }
}
