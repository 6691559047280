<script setup lang="ts">
import {
  IForm,
  IFormError,
  IFormGroup,
  IFormLabel,
  IInput,
  IRadioButtons,
  ITextarea,
  useForm,
} from '@inkline/inkline'
import moment from 'moment'
import DatePickerInput from './inputs/DatePickerInput.vue'
import FormConsent from './FormConsent.vue'
import Icon from '~/components/global/Icon.vue'
import { useAPIRequest } from '~/composables/api/useAPIRequest'
import useQuickOfferModalStore from '~/stores/modals/useQuickOfferModal'
import getFormTrackingData from '~/utils/tracking/getFormTrackingData'
import trackGtmEvent from '~/utils/tracking/tackGtmEvent'
import type { EstateType } from '~/types/EstateType'

const { executeRecaptcha } = useGoogleRecaptcha()
const quickOfferModalStore = useQuickOfferModalStore()
const { t } = useI18n()
const serviceLineOptions = [
  {
    label: t('forms.quickOffer.fields.service_line.office'),
    id: 'Office Agency',
    type: 'office',
  },
  {
    label: t('forms.quickOffer.fields.service_line.warehouse'),
    id: 'Industrial Agency',
    type: 'warehouse',
  },
  {
    label: t('forms.quickOffer.fields.service_line.retail'),
    id: 'Retail Agency',
    type: 'retail',
  },
]
const serviceLineChecked = ref(serviceLineOptions[0].id)
const serviceLineCheckedData = computed(() => {
  return serviceLineOptions.find(
    (option) => option.id === serviceLineChecked.value
  )
})
const { form, schema, validate } = useForm({
  service_line: {
    value: serviceLineChecked.value,
  },
  name: {
    validators: [
      {
        name: 'required',
        message: t('forms.quickOffer.fields.name.requiredMessage'),
      },
    ],
  },
  email: {
    validators: [
      {
        name: 'required',
        message: t('forms.quickOffer.fields.email.requiredMessage'),
      },
      {
        name: 'email',
        message: t('forms.quickOffer.fields.email.invalidMessage'),
      },
    ],
  },
  phone: {
    validators: [
      {
        name: 'required',
        message: t('forms.quickOffer.fields.phone.requiredMessage'),
      },
      {
        name: 'custom',
        message: t('forms.quickOffer.fields.phone.invalidMessage'),
        validator: (v: string) => !v || /^\+?[0-9 ]+$/.test(v),
      },
    ],
  },
  location: {
    validators: [
      {
        name: 'required',
        message: t('forms.quickOffer.fields.location.requiredMessage'),
      },
    ],
  },
  size: {
    validators: [
      {
        name: 'required',
        message: t('forms.quickOffer.fields.size.requiredMessage'),
      },
    ],
  },
  lease_start: {
    validators: [
      {
        name: 'custom',
        message: t('forms.quickOffer.fields.lease_start.requiredMessage'),
        validator: (v: unknown) => {
          if (!v || typeof v !== 'string') {
            return false
          }

          try {
            return moment(v).isValid()
          } catch (error) {
            return false
          }
        },
      },
    ],
  },
  message: {
    validators: [
      {
        name: 'required',
        message: t('forms.quickOffer.fields.message.requiredMessage'),
      },
      {
        name: 'maxLength',
        value: 1000,
        message: t('forms.quickOffer.fields.message.maxLengthMessage', {
          _0: 1000,
        }),
      },
    ],
  },
})

const textareaCharsRemaining = computed(() => {
  const inputValue = (schema.value.message.value ?? '') as string
  const charactersRemaining = 1000 - inputValue.length

  let message = t(
    'forms.quickOffer.fields.message.characterRemaining',
    { _0: formatNumber(charactersRemaining) },
    charactersRemaining
  )

  if (charactersRemaining < 0) {
    message = t(
      'forms.quickOffer.fields.message.characterExceeded',
      { _0: formatNumber(Math.abs(charactersRemaining)) },
      Math.abs(charactersRemaining)
    )
  }

  return {
    message,
    charactersRemaining,
  }
})

function setValidatorToShowErrors() {
  let isFirst = true
  for (const schemaFieldKey of Object.keys(schema.value)) {
    const schemaField = schema.value[schemaFieldKey]

    if (schemaField?.constructor !== Object || schemaField?.valid) {
      continue
    }

    if (isFirst) {
      ;(
        document.querySelector(`[name="${schemaFieldKey}"]`) as HTMLInputElement
      )?.focus()

      isFirst = false
    }

    schemaField.value = schemaField.value ?? ''
    schemaField.pristine = false
    schemaField.dirty = true
  }
}

function resetForm() {
  for (const schemaFieldKey of Object.keys(schema.value)) {
    const schemaField = schema.value[schemaFieldKey]

    if (schemaField?.constructor !== Object) {
      continue
    }

    delete schemaField.value
    schemaField.pristine = true
    schemaField.dirty = false
  }

  sessionStorage.removeItem('quickOfferForm')
}

const isSubmitting = ref(false)
async function onSubmit() {
  isSubmitting.value = true

  await validate()

  if (!schema.value.valid) {
    setValidatorToShowErrors()

    isSubmitting.value = false

    return false
  }

  form.value.lease_start = moment(form.value.lease_start).format('YYYY-MM-DD')

  const { token } = await executeRecaptcha('quick_offer')

  useAPIRequest('/offer/send', {
    method: 'POST',
    body: {
      ...form.value,
      type: 'Let us help you',
      url: window.location.origin + window.location.pathname,
      token,
    },
  })
    .then((response) => {
      if (!response.status) {
        showToast({
          title: t('forms.quickOffer.errorTitle'),
          message: response.error || response.message,
          color: 'danger',
        })

        return
      }

      showToast({
        title: t('forms.quickOffer.successTitle'),
        message: t('forms.quickOffer.successMessage'),
      })

      const trackingCategories = {
        office: 'Kancelárske priestory',
        warehouse: 'Sklady, haly a pozemky',
        retail: 'Obchodné priestory',
        general: 'general',
      } as any

      trackGtmEvent('odoslanie_formularu', {
        form_type: 'rychla-ponuka',
        ...getFormTrackingData(),
        kategoria:
          trackingCategories[serviceLineCheckedData?.value?.type || 'general'],
      })

      resetForm()
      quickOfferModalStore.close()
    })
    .catch((error) => {
      showToast({
        title: t('forms.quickOffer.errorTitle'),
        message: error.message,
        color: 'danger',
      })
    })
    .finally(() => {
      isSubmitting.value = false
    })
}

// watch form fields for change and save it to sessionStorage
watch(
  form,
  () => {
    sessionStorage.setItem('quickOfferForm', JSON.stringify(form.value))
  },
  { deep: true }
)

const router = useRouter()
watch(
  () => router.currentRoute.value,
  () => {
    const route = useRoute()
    const routeName = route.name?.toString()
    let defaultType: EstateType | undefined

    if (routeName?.startsWith('office')) {
      defaultType = 'office'
    } else if (routeName?.startsWith('warehouse')) {
      defaultType = 'warehouse'
    } else if (routeName?.startsWith('retail')) {
      defaultType = 'retail'
    }

    if (defaultType) {
      const defaultServiceLine = serviceLineOptions.find(
        (option) => option.type === defaultType
      )?.id

      if (!defaultServiceLine) return

      schema.value.service_line.value = defaultServiceLine
    }
  },
  {
    immediate: true,
  }
)

onMounted(() => {
  const savedForm = JSON.parse(sessionStorage.getItem('quickOfferForm') ?? '{}')

  for (const schemaFieldKey of Object.keys(schema.value)) {
    const schemaField = schema.value[schemaFieldKey]

    if (schemaField?.constructor !== Object) {
      continue
    }

    if (savedForm[schemaFieldKey]) {
      schemaField.value = savedForm[schemaFieldKey]
    }
  }
})

watch(
  () => quickOfferModalStore.isOpened,
  (isOpened) => {
    if (!isOpened) return

    trackGtmEvent('videnie_formularu', {
      form_type: 'rychla-ponuka',
      ...getFormTrackingData(),
    })
  }
)
</script>

<template>
  <IForm
    v-model="schema"
    novalidate
    class="c-quick-offer-form has-floating-labels"
    @submit="onSubmit"
  >
    <IFormGroup class="c-quick-offer-form__service-line">
      <IRadioButtons
        v-model="serviceLineChecked"
        name="service_line"
        :options="serviceLineOptions"
      >
        <template #option="{ option }">
          <span :data-text="option.label">
            <span>{{ option.label }}</span>
          </span>
        </template>
      </IRadioButtons>
    </IFormGroup>

    <IFormGroup>
      <IFormLabel>
        <span class="sc-label"
          >{{ $t('forms.quickOffer.fields.name.label') }}
          <span class="sc-required">*</span>
        </span>
      </IFormLabel>
      <IInput name="name" placeholder="" />
      <IFormError for="name" />
    </IFormGroup>

    <IFormGroup>
      <IFormLabel>
        <span class="sc-label"
          >{{ $t('forms.quickOffer.fields.email.label') }}
          <span class="sc-required">*</span>
        </span>
      </IFormLabel>
      <IInput name="email" type="email" placeholder="" />
      <IFormError for="email" />
    </IFormGroup>

    <IFormGroup>
      <IFormLabel>
        <span class="sc-label"
          >{{ $t('forms.quickOffer.fields.phone.label') }}
          <span class="sc-required">*</span>
        </span>
      </IFormLabel>
      <IInput name="phone" type="tel" placeholder="" />
      <IFormError for="phone" />
    </IFormGroup>

    <IFormGroup>
      <IFormLabel>
        <span class="sc-label"
          >{{ $t('forms.quickOffer.fields.location.label') }}
          <span class="sc-required">*</span>
        </span>
      </IFormLabel>
      <IInput name="location" placeholder="" />
      <IFormError for="location" />
    </IFormGroup>

    <IFormGroup>
      <IFormLabel>
        <span class="sc-label"
          >{{ $t('forms.quickOffer.fields.size.label') }}
          <span class="sc-required">*</span>
        </span>
      </IFormLabel>
      <IInput name="size" placeholder="" />
      <IFormError for="size" />
    </IFormGroup>

    <IFormGroup>
      <IFormLabel>
        <span class="sc-label"
          >{{ $t('forms.quickOffer.fields.lease_start.label') }}
          <span class="sc-required">*</span>
        </span>
      </IFormLabel>
      <DatePickerInput
        name="lease_start"
        placeholder=""
        :enable-time-picker="false"
        :min-date="new Date()"
        format="dd. MM. yyyy"
      />
      <IFormError for="lease_start" />
    </IFormGroup>

    <IFormGroup>
      <IFormLabel>
        <span class="sc-label">
          <span class="sc-base">
            {{ $t('forms.quickOffer.fields.message.label.base') }}
            <span class="sc-required">*</span>
          </span>
          <small class="sc-additional">
            {{
              $t(
                `forms.quickOffer.fields.message.label.${serviceLineCheckedData?.type}`
              )
            }}
          </small>
        </span>
      </IFormLabel>
      <div
        class="sc-textarea-sizer"
        :data-input-content="schema.message.value ?? ''"
      >
        <ITextarea name="message" placeholder="">
          <template #append>
            <span
              :class="[
                'sc-textarea-message',
                textareaCharsRemaining.charactersRemaining < 0 &&
                  'has-overflow',
              ]"
              >{{ textareaCharsRemaining.message }}</span
            >
          </template>
        </ITextarea>
      </div>
      <IFormError for="message" />
    </IFormGroup>

    <IFormGroup>
      <IButton
        class="has-icon"
        color="secondary"
        type="submit"
        size="lg"
        :loading="isSubmitting"
        :show-loading-icon="true"
        @click="onSubmit"
      >
        {{ $t('forms.quickOffer.submit') }}

        <Icon name="arrow-right-thick" />
      </IButton>
    </IFormGroup>

    <IFormGroup>
      <FormConsent class="c-quick-offer-form__consent" />
    </IFormGroup>
  </IForm>
</template>

<style lang="scss" scoped>
@use '@inkline/inkline/css/mixins' as *;

.c-quick-offer-form {
  &__service-line {
    margin-bottom: 30px;

    :deep(.radio-buttons) {
      box-shadow: none;
      width: 100%;
      display: grid;
      grid-template-columns: repeat(3, auto);
      justify-content: center;
      gap: 0;
      padding: 0;
      border: none;

      .button {
        --button--background: var(--color-light);
        --button--border-width: 0 0 2px 0;
        --button--border-color: var(--color-light);
        --button--font-weight: 400;
        --button--padding: 4px 16px 12px;
        --button--min-height: 0;

        --button--active--background: var(--color-light);
        --button--active--color: var(--color-primary);
        --button--active--border-color: var(--color-primary);
        --button--active--font-weight: 600;

        --button--hover--background: var(--color-light);
        --button--hover--color: var(--color-primary);

        min-width: 0;
        white-space: normal;
        margin: 0;

        @include breakpoint-down('xs') {
          --button--border-width: 0;
          --button--padding: 5px 8px;
          --button--font-size: 14px;

          --checkable-button-group--button--active--background: var(
            --color-primary
          );
          --button--active--background: var(--color-primary);
          --button--active--color: var(--color-light);
          --button--active--font-weight: 500;

          border-radius: 4px;
        }

        [data-text] {
          display: grid;
          place-items: center;

          &::before,
          > * {
            grid-area: 1 / 1 / 2 / 2;
          }

          &::before {
            content: attr(data-text);
            font-weight: 600;
            pointer-events: none;
            user-select: none;
            visibility: hidden;
          }
        }
      }
    }
  }

  :deep(.form-group) {
    .sc-textarea-sizer {
      --input--padding: 12px 18px 36px;
      --input--font-size: 16px;
      --input--line-height: 1.2;

      display: grid;

      /* stylelint-disable-next-line no-descending-specificity */
      > * {
        grid-row: 1 / 2;
        grid-column: 1 / 2;
        height: 100%;
      }

      &::before {
        content: attr(data-input-content) ' ';
        visibility: hidden;
        white-space: pre-wrap;
        grid-row: 1 / 2;
        grid-column: 1 / 2;
        padding: var(--input--padding);
        font-size: var(--input--font-size);
        line-height: var(--input--line-height);
        pointer-events: none;
      }
    }

    .input-append {
      .sc-textarea-message {
        position: absolute;
        padding: 0;
        right: 15px;
        bottom: 10px;
        font-size: 16px;
        font-weight: 400;
        color: var(--color-solid-grey-dark);

        &.has-overflow {
          color: var(--color-red);
        }
      }
    }

    &:has(.sc-label .sc-additional) {
      .sc-label {
        position: relative;
      }

      .sc-additional {
        position: absolute;
        font-size: 16px;
        line-height: 1.2;
        font-weight: 400;
        left: 0;
        top: calc(100% + 6px);
        width: 500px;
        max-width: calc(100vw - 330px);
        transition: transform var(--transition-duration)
          var(--transition-timing-function);
        will-change: transform;
        color: var(--color-solid-grey-dark);

        @include breakpoint-down('sm') {
          max-width: calc(100vw - 120px);
        }
      }

      &:has(textarea:focus),
      &:not(:has(textarea:placeholder-shown)) {
        .sc-additional {
          transform: translateX(4px);
        }
      }

      &:not(:has(textarea:placeholder-shown)) {
        .sc-additional {
          display: none;
        }
      }
    }
  }

  &__consent {
    font-size: 15px;
    line-height: 1.28;
    font-weight: 400;
    color: var(--color-text-dark-2);
  }
}
</style>
