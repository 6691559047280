<script setup lang="ts">
const props = defineProps<{
  variant?: 'default' | 'narrow' | 'narrower'
}>()

const classes = computed(() => {
  return [
    'l-container',
    props.variant && `l-container--variant-${props.variant}`,
  ]
})
</script>

<template>
  <div :class="classes">
    <slot />
  </div>
</template>

<style lang="scss" scoped>
@use '@inkline/inkline/css/mixins' as *;

.l-container {
  width: 100%;
  max-width: 1360px;
  margin-left: auto;
  margin-right: auto;
  padding-left: var(--container-padding);
  padding-right: var(--container-padding);

  &--variant-narrow {
    max-width: 940px;
  }
  &--variant-narrower {
    max-width: 800px;
  }

  @include breakpoint-down('xl') {
    max-width: 1140px;

    &--variant-narrow {
      max-width: 940px;
    }
    &--variant-narrower {
      max-width: 800px;
    }
  }

  @include breakpoint-down('lg') {
    max-width: 100%;

    &--variant-narrow {
      max-width: 940px;
    }
    &--variant-narrower {
      max-width: 800px;
    }
  }

  @include breakpoint-down('md') {
    max-width: 100%;
  }
}
</style>
