<script setup lang="ts">
import { useFormValidationError, useValidation } from '@inkline/inkline'
import VueDatePicker from '@vuepic/vue-datepicker'
import moment from 'moment'

import '@vuepic/vue-datepicker/dist/main.css'

const props = withDefaults(
  defineProps<{
    name: string
    format: string
    validate?: boolean
  }>(),
  {
    validate: true,
  }
)

const { locale } = useI18n()
const name = toRef(props, 'name')
const validate = toRef(props, 'validate')

const { schema, onInput: schemaOnInput } = useValidation({
  name,
  validate,
})
const { hasError } = useFormValidationError({
  schema,
  error: ref(['touched', 'dirty', 'invalid']),
})

const value = computed(() => {
  if (schema.value && validate.value) {
    return schema.value.value
  }

  return undefined
})

function onInput(value: any) {
  schemaOnInput(name, moment(value).toISOString())
}
</script>

<template>
  <VueDatePicker
    v-model="value"
    v-bind="$attrs"
    :auto-apply="true"
    :clearable="false"
    :auto-position="false"
    :locale="locale"
    :format="format"
    :input-class-name="hasError ? 'is-invalid' : ''"
    hide-input-icon
    @update:model-value="onInput"
  />
</template>

<style lang="scss">
@import '~/assets/styles/lib/datepicker-overrides/overrides';
</style>

<!-- <style lang="scss" scoped>
@use '@inkline/inkline/css/mixins' as *;
</style> -->
