import { defineStore } from 'pinia'
import { preparedConfigurations } from '#nuxt-prepare'
import type { ConfigurationsMap } from '~/prepare/configurations.prepare'

export default defineStore('configurationsStore', () => {
  const { locale } = useI18n()

  const map = preparedConfigurations[
    locale.value as keyof typeof preparedConfigurations
  ] as ConfigurationsMap

  function getConfiguration(key: string): string {
    return map[key] ?? key
  }

  return {
    getConfiguration,
  }
})
