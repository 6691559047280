import { $fetch } from 'ofetch'
import type { FetchOptions } from 'ofetch'

export interface ApiRequestFetchOptions extends FetchOptions {
  requestTimeout?: number
}

export const useAPIRequest = async (
  endpoint: string,
  config: ApiRequestFetchOptions = {},
  abortController?: AbortController
): Promise<any> => {
  const runtimeConfig = useRuntimeConfig()
  const { $i18n } = useNuxtApp()

  const { requestTimeout = 30 * 1000 } = config
  const baseApiUrl = runtimeConfig.public.BASE_API_URL
  const controller = abortController || new AbortController()
  const abortTimeout = setTimeout(() => controller.abort(), requestTimeout)

  const client = $fetch.create({
    retry: 0,
    baseURL: baseApiUrl,
    signal: controller.signal,
  })

  config.headers = {
    'X-App-Locale': $i18n.locale.value,
    ...config.headers,
  }

  const response = await client(endpoint, { ...config })

  clearTimeout(abortTimeout)

  return response
}
