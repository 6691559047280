<script setup lang="ts">
import useFilterSearchPreDefinedValues from '~/composables/filter/useFilterSearchPreDefinedValues'
import type { useIndexSearch } from '~/composables/index/useIndexSearch'

const { locale } = useI18n()
const props = defineProps<{
  indexSearch: ReturnType<typeof useIndexSearch>
}>()

const preDefinedSearchValues = useFilterSearchPreDefinedValues()
const filterLinks = computed(() => {
  return preDefinedSearchValues[props.indexSearch.activeNavItemId.value][
    locale.value
  ].map((name) => ({
    name,
    link: getEstateListingLink({
      estateType: props.indexSearch.activeNavItemId.value,
      query: {
        'filter': encodeURIComponent(
          JSON.stringify({
            search: name,
          })
        ),
        'scroll-to-hash': 'listing-scroll-hook',
      },
    }),
  }))
})
</script>

<template>
  <div class="c-index-hero-search-links">
    <NuxtLink
      v-for="link in filterLinks"
      :key="link.name"
      :to="link.link"
      class="c-link c-link--inherit c-link--underlined"
    >
      {{ link.name }}
    </NuxtLink>

    <span>...</span>
  </div>
</template>

<style lang="scss" scoped>
@use '@inkline/inkline/css/mixins' as *;

.c-index-hero-search-links {
  display: flex;
  flex-wrap: wrap;
  gap: 8px 24px;
  color: var(--color-light);
  font-size: 18px;
  font-weight: 500;
  align-items: center;
  justify-content: center;

  @include breakpoint-down('sm') {
    gap: 4px 16px;
    font-size: 14px;
  }
}
</style>
