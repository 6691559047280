import type { EstateType } from '~/types/EstateType'

export function useIndexSearch() {
  const { t } = useI18n()

  const navItems: {
    id: EstateType
    label: string
    searchPlaceholder: string
  }[] = [
    {
      id: 'office',
      label: t('pages.index.navItems.office.label'),
      searchPlaceholder: t('pages.index.navItems.office.placeholder'),
    },
    {
      id: 'warehouse',
      label: t('pages.index.navItems.warehouse.label'),
      searchPlaceholder: t('pages.index.navItems.warehouse.placeholder'),
    },
    {
      id: 'retail',
      label: t('pages.index.navItems.retail.label'),
      searchPlaceholder: t('pages.index.navItems.retail.placeholder'),
    },
  ]

  const activeNavItemId = ref(navItems[0].id) as Ref<EstateType>
  const activeNavItem = computed(
    () =>
      navItems.find((item) => item.id === activeNavItemId.value) || navItems[0]
  )

  function setActiveNavItemId(id: EstateType) {
    activeNavItemId.value = id
  }

  function submitSearch(value: string) {
    const query = {
      'scroll-to-hash': 'listing-scroll-hook',
    } as Record<string, string>

    if (value) {
      query.filter = encodeURIComponent(JSON.stringify({ search: value }))
    }

    navigateTo(
      getEstateListingLink({
        estateType: activeNavItemId.value,
        query,
      })
    )
  }

  return {
    navItems,
    activeNavItemId,
    activeNavItem,
    setActiveNavItemId,
    submitSearch,
  }
}
