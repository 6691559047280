import { VueReCaptcha } from 'vue-recaptcha-v3'

export default defineNuxtPlugin((nuxtApp: any) => {
  const config = useRuntimeConfig()
  const locale = nuxtApp.$i18n.locale.value

  const options = {
    siteKey: config.public.GOOGLE_RECAPTCHA_SITE_KEY,
    loaderOptions: {
      autoHideBadge: true,
      useRecaptchaNet: true,
      renderParameters: {
        hl: locale,
      },
    },
  }

  nuxtApp.vueApp.use(VueReCaptcha, options)
})
