<script setup lang="ts">
import { useForm } from '@inkline/inkline'
import Icon from '~/components/global/Icon.vue'
import type { useIndexSearch } from '~/composables/index/useIndexSearch'
import trackGtmEvent from '~/utils/tracking/tackGtmEvent'

const props = defineProps<{
  indexSearch: ReturnType<typeof useIndexSearch>
  isOnErrorPage?: boolean
}>()

const { form, schema } = useForm({
  searchKeyword: {},
})

function onSubmit() {
  trackGtmEvent('vyhladavanie', {
    search_term: form.value.searchKeyword,
    page_type: props.isOnErrorPage ? 'error-page' : 'homepage',
  })

  props.indexSearch.submitSearch(form.value.searchKeyword)
}
</script>

<template>
  <IForm
    v-model="schema"
    novalidate
    onsubmit="return false;"
    class="c-index-hero-search-form"
    @submit="onSubmit"
  >
    <IInput
      name="searchKeyword"
      :placeholder="indexSearch.activeNavItem.value.searchPlaceholder"
    >
      <template #prefix>
        <Icon name="search" />
      </template>

      <template #suffix>
        <IButton class="sc-desktop" color="primary" type="submit">{{
          $t('pages.index.hero.searchSubmit')
        }}</IButton>

        <IButton class="sc-phone" color="secondary" type="submit">
          <Icon name="search" />
        </IButton>
      </template>
    </IInput>
  </IForm>
</template>

<style lang="scss" scoped>
@use '@inkline/inkline/css/mixins' as *;

.c-index-hero-search-form {
  :deep(.input) {
    --input--padding: 0 0 0 50px;

    position: relative;
    border-radius: 6px;
    align-items: stretch;

    @include breakpoint-down('sm') {
      --input--border-width: 0;
      --input--padding: 0 0 0 16px;

      font-size: 15px;
    }

    & > input::placeholder {
      font-weight: 400;
    }

    .input-prefix,
    .input-suffix {
      border: none;
      align-self: stretch;
    }

    .input-prefix {
      font-size: 18px;
      color: var(--color-primary);
      position: absolute;
      height: 100%;
      pointer-events: none;

      @include breakpoint-down('sm') {
        display: none;
      }
    }

    .input-suffix {
      padding: 4px;

      .button {
        height: 100%;
        min-width: 120px;

        &.sc-phone {
          display: none;
          min-width: unset;
          min-height: unset;
          padding: 0;
          width: 40px;
          height: 40px;
          border-radius: 4px;
        }

        @include breakpoint-down('sm') {
          display: none;

          &.sc-phone {
            display: block;
          }
        }
      }
    }
  }
}
</style>
~/utils/tracking/tackGtmEvent
