import useEstateDetailDataStore from '~/stores/useEstateDetailDataStore'
import type { EstateDetailInterface } from '~/types/api/EstateDetail'
import type { EstateListing } from '~/types/api/EstateListing'

export default function (estateData?: EstateDetailInterface | EstateListing) {
  if (!estateData) {
    const estateDetailDataStore = useEstateDetailDataStore()

    estateData = estateDetailDataStore.data as EstateDetailInterface
  }

  if (!estateData)
    return {
      nazov: 'general',
      kategoria: 'general',
      typ_ponuky: 'general',
      druh_ponuky: 'general',
      dostupnost: 'general',
      kraj: 'general',
      mesto: 'general',
      ulica: 'general',
    }

  const { $i18n } = useNuxtApp()
  const { locale, t, te } = $i18n

  const pageTypesData = {
    office: {
      kategoria: 'Kancelárske priestory',
      typ_ponuky: 'general',
      druh_ponuky: 'general',
      dostupnost: estateData.availability_text,
      kraj: 'general',
      mesto: estateData.town,
      ulica: estateData.street,
    },
    warehouse: {
      kategoria: 'Sklady, haly a pozemky',
      typ_ponuky: te(`parameters.values.${estateData.offer_type}`)
        ? t(`parameters.values.${estateData.offer_type}`)
        : estateData.offer_type,
      druh_ponuky: te(`parameters.values.${estateData.sub_type}`)
        ? t(`parameters.values.${estateData.sub_type}`)
        : estateData.sub_type,
      dostupnost: estateData.availability_text,
      kraj: locale.value === 'sk' ? estateData.hub : estateData.hub_en,
      mesto: estateData.town,
      ulica: 'general',
    },
    retail: {
      kategoria: 'Obchodné priestory',
      typ_ponuky: 'general',
      druh_ponuky: te(`parameters.values.${estateData.sub_type}`)
        ? t(`parameters.values.${estateData.sub_type}`)
        : estateData.sub_type,
      dostupnost: 'general',
      kraj: 'general',
      mesto: estateData.town,
      ulica: estateData.street,
    },
  }

  return {
    nazov: estateData.name,
    ...pageTypesData[estateData.type],
  }
}
