import { defineNuxtPlugin, defineNuxtLink } from '#app';
import { markRaw } from 'vue';
import { Inkline } from '@inkline/inkline';
import { colorModeLocalStorageKey } from '@inkline/inkline/plugins';

export default defineNuxtPlugin((nuxtApp) => {
    const NuxtLink = defineNuxtLink({ componentName: 'InklineNuxtLink' });

    nuxtApp.vueApp.use(Inkline, {
        renderMode: 'universal',
        routerComponent: markRaw(NuxtLink),
        ...{"renderMode":"universal","colorMode":"light"}
    });

    nuxtApp.hook('app:suspense:resolve', () => {
        const storedColorMode = localStorage.getItem(colorModeLocalStorageKey);
        if (storedColorMode) {
            nuxtApp.vueApp.config.globalProperties.$inkline.options.colorMode = storedColorMode;
        }
    })
});
