import { defineStore } from 'pinia'

function getInitialValue() {
  const pagesWithFancyHeader = [
    'about-us',
    'contact',
    'index',
    'our-services',
    'warehouses',
    'news',
    'news-tag-tag_id',
    'offices',
    'references',
    'retail',
    'favorites',
  ]

  const route = useRoute()
  const routeName = route.name?.toString().split('___')[0] || ''

  return pagesWithFancyHeader.includes(routeName)
}

export const useFancyHeaderStore = defineStore('fancyHeader', () => {
  const value = ref(getInitialValue())

  function setValue(newValue: boolean) {
    value.value = newValue
  }

  return {
    value,
    setValue,
  }
})
