import { default as about_45usNqCokbL9aFMeta } from "/opt/atlassian/pipelines/agent/build/pages/about-us.vue?macro=true";
import { default as contactggdDqdGlSrMeta } from "/opt/atlassian/pipelines/agent/build/pages/contact.vue?macro=true";
import { default as favorites6PlaUcDx3pMeta } from "/opt/atlassian/pipelines/agent/build/pages/favorites.vue?macro=true";
import { default as indexOD9t3F2bSJMeta } from "/opt/atlassian/pipelines/agent/build/pages/index.vue?macro=true";
import { default as _91tag_id_93oJErMBtR0ZMeta } from "/opt/atlassian/pipelines/agent/build/pages/news/[tag]/[tag_id].vue?macro=true";
import { default as _91id_93TG0bSvVfbNMeta } from "/opt/atlassian/pipelines/agent/build/pages/news/article/[slug]/[id].vue?macro=true";
import { default as indexPOel7UPVy5Meta } from "/opt/atlassian/pipelines/agent/build/pages/news/index.vue?macro=true";
import { default as newslccLAPUChdMeta } from "/opt/atlassian/pipelines/agent/build/pages/news.vue?macro=true";
import { default as _91id_93EEkSfZnnxuMeta } from "/opt/atlassian/pipelines/agent/build/pages/offices/[slug]/[id].vue?macro=true";
import { default as indexxN6CseWW7GMeta } from "/opt/atlassian/pipelines/agent/build/pages/offices/index.vue?macro=true";
import { default as mapfKlOlybnVdMeta } from "/opt/atlassian/pipelines/agent/build/pages/offices/map.vue?macro=true";
import { default as offices65cJVO7tzdMeta } from "/opt/atlassian/pipelines/agent/build/pages/offices.vue?macro=true";
import { default as our_45services7HVelAMzAZMeta } from "/opt/atlassian/pipelines/agent/build/pages/our-services.vue?macro=true";
import { default as _91id_93FJkFqiZmMuMeta } from "/opt/atlassian/pipelines/agent/build/pages/references/[slug]/[id].vue?macro=true";
import { default as indexUz0AmMSWVTMeta } from "/opt/atlassian/pipelines/agent/build/pages/references/index.vue?macro=true";
import { default as referencesT5zq18HDxHMeta } from "/opt/atlassian/pipelines/agent/build/pages/references.vue?macro=true";
import { default as _91id_9320mq6gGSQLMeta } from "/opt/atlassian/pipelines/agent/build/pages/retail/[slug]/[id].vue?macro=true";
import { default as indexhoBWzu9mFJMeta } from "/opt/atlassian/pipelines/agent/build/pages/retail/index.vue?macro=true";
import { default as mapkhJtOEAao0Meta } from "/opt/atlassian/pipelines/agent/build/pages/retail/map.vue?macro=true";
import { default as retail3o9wcBjZBCMeta } from "/opt/atlassian/pipelines/agent/build/pages/retail.vue?macro=true";
import { default as _91id_93cQ69D2m1LgMeta } from "/opt/atlassian/pipelines/agent/build/pages/warehouses/[slug]/[id].vue?macro=true";
import { default as indexCHarojlpnJMeta } from "/opt/atlassian/pipelines/agent/build/pages/warehouses/index.vue?macro=true";
import { default as map2KImvNuUKTMeta } from "/opt/atlassian/pipelines/agent/build/pages/warehouses/map.vue?macro=true";
import { default as warehouses3lDMEctiIdMeta } from "/opt/atlassian/pipelines/agent/build/pages/warehouses.vue?macro=true";
import { default as component_45stubMz1LRITiSVMeta } from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubMz1LRITiSV } from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "about-us___sk",
    path: "/o-nas",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/about-us.vue")
  },
  {
    name: "about-us___en",
    path: "/en/about-us",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/about-us.vue")
  },
  {
    name: "contact___sk",
    path: "/kontakt",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/contact.vue")
  },
  {
    name: "contact___en",
    path: "/en/contact",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/contact.vue")
  },
  {
    name: "favorites___sk",
    path: "/oblubene",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/favorites.vue")
  },
  {
    name: "favorites___en",
    path: "/en/favorites",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/favorites.vue")
  },
  {
    name: "index___sk",
    path: "/",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/index.vue")
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/index.vue")
  },
  {
    name: newslccLAPUChdMeta?.name,
    path: "/novinky",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/news.vue"),
    children: [
  {
    name: "news-tag-tag_id___sk",
    path: ":tag()/:tag_id()",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/news/[tag]/[tag_id].vue")
  },
  {
    name: "news-article-slug-id___sk",
    path: "clanok/:slug()/:id()",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/news/article/[slug]/[id].vue")
  },
  {
    name: "news___sk",
    path: "/novinky",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/news/index.vue")
  }
]
  },
  {
    name: newslccLAPUChdMeta?.name,
    path: "/en/news",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/news.vue"),
    children: [
  {
    name: "news-tag-tag_id___en",
    path: ":tag()/:tag_id()",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/news/[tag]/[tag_id].vue")
  },
  {
    name: "news-article-slug-id___en",
    path: "article/:slug()/:id()",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/news/article/[slug]/[id].vue")
  },
  {
    name: "news___en",
    path: "/en/news",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/news/index.vue")
  }
]
  },
  {
    name: offices65cJVO7tzdMeta?.name,
    path: "/kancelarie",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/offices.vue"),
    children: [
  {
    name: "offices-slug-id___sk",
    path: ":slug()/:id()",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/offices/[slug]/[id].vue")
  },
  {
    name: "offices___sk",
    path: "/kancelarie",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/offices/index.vue")
  },
  {
    name: "offices-map___sk",
    path: "mapa",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/offices/map.vue")
  }
]
  },
  {
    name: offices65cJVO7tzdMeta?.name,
    path: "/en/offices",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/offices.vue"),
    children: [
  {
    name: "offices-slug-id___en",
    path: ":slug()/:id()",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/offices/[slug]/[id].vue")
  },
  {
    name: "offices___en",
    path: "/en/offices",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/offices/index.vue")
  },
  {
    name: "offices-map___en",
    path: "map",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/offices/map.vue")
  }
]
  },
  {
    name: "our-services___sk",
    path: "/nase-sluzby",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/our-services.vue")
  },
  {
    name: "our-services___en",
    path: "/en/our-services",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/our-services.vue")
  },
  {
    name: referencesT5zq18HDxHMeta?.name,
    path: "/referencie",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/references.vue"),
    children: [
  {
    name: "references-slug-id___sk",
    path: "/referencia/:slug()/:id()",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/references/[slug]/[id].vue")
  },
  {
    name: "references___sk",
    path: "/referencie",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/references/index.vue")
  }
]
  },
  {
    name: referencesT5zq18HDxHMeta?.name,
    path: "/en/references",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/references.vue"),
    children: [
  {
    name: "references-slug-id___en",
    path: "/en/reference/:slug()/:id()",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/references/[slug]/[id].vue")
  },
  {
    name: "references___en",
    path: "/en/references",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/references/index.vue")
  }
]
  },
  {
    name: retail3o9wcBjZBCMeta?.name,
    path: "/obchodne-priestory",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/retail.vue"),
    children: [
  {
    name: "retail-slug-id___sk",
    path: ":slug()/:id()",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/retail/[slug]/[id].vue")
  },
  {
    name: "retail___sk",
    path: "/obchodne-priestory",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/retail/index.vue")
  },
  {
    name: "retail-map___sk",
    path: "mapa",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/retail/map.vue")
  }
]
  },
  {
    name: retail3o9wcBjZBCMeta?.name,
    path: "/en/retail",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/retail.vue"),
    children: [
  {
    name: "retail-slug-id___en",
    path: ":slug()/:id()",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/retail/[slug]/[id].vue")
  },
  {
    name: "retail___en",
    path: "/en/retail",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/retail/index.vue")
  },
  {
    name: "retail-map___en",
    path: "map",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/retail/map.vue")
  }
]
  },
  {
    name: warehouses3lDMEctiIdMeta?.name,
    path: "/sklady-haly-a-pozemky",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/warehouses.vue"),
    children: [
  {
    name: "warehouses-slug-id___sk",
    path: ":slug()/:id()",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/warehouses/[slug]/[id].vue")
  },
  {
    name: "warehouses___sk",
    path: "/sklady-haly-a-pozemky",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/warehouses/index.vue")
  },
  {
    name: "warehouses-map___sk",
    path: "mapa",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/warehouses/map.vue")
  }
]
  },
  {
    name: warehouses3lDMEctiIdMeta?.name,
    path: "/en/warehouses",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/warehouses.vue"),
    children: [
  {
    name: "warehouses-slug-id___en",
    path: ":slug()/:id()",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/warehouses/[slug]/[id].vue")
  },
  {
    name: "warehouses___en",
    path: "/en/warehouses",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/warehouses/index.vue")
  },
  {
    name: "warehouses-map___en",
    path: "map",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/warehouses/map.vue")
  }
]
  },
  {
    name: component_45stubMz1LRITiSVMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubMz1LRITiSV
  }
]