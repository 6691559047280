import type { EstateType } from '~/types/EstateType'

export default function useFilterSearchPreDefinedValues() {
  const values: {
    [key in EstateType]: {
      [key: string]: string[]
    }
  } = {
    office: {
      sk: [
        'Bratislava',
        'Košice',
        'Banská Bystrica',
        'Žilina',
        'Prešov',
        'Poprad',
        'Piešťany',
      ],
      en: [
        'Bratislava',
        'Košice',
        'Banská Bystrica',
        'Žilina',
        'Prešov',
        'Poprad',
        'Piešťany',
      ],
    },
    warehouse: {
      sk: [
        'Bratislava',
        'Bratislavský kraj',
        'Trnavský kraj',
        'Nitriansky kraj',
        'Žilinský kraj',
        'Košický kraj',
        'Prešovský kraj',
        'Trenčiansky kraj',
        'Banskobystrický kraj',
      ],
      en: [
        'Bratislava',
        'Bratislava Region',
        'Trnava Region',
        'Nitra Region',
        'Žilina Region',
        'Košice Region',
        'Prešov Region',
        'Trenčín Region',
        'Banská Bystrica Region',
      ],
    },
    retail: {
      sk: [
        'Bratislava',
        'Košice',
        'Žilina',
        'Banská Bystrica',
        'Prešov',
        'Trnava',
        'Nitra',
        'Trenčín',
        'Zvolen',
        'Poprad',
      ],
      en: [
        'Bratislava',
        'Košice',
        'Žilina',
        'Banská Bystrica',
        'Prešov',
        'Trnava',
        'Nitra',
        'Trenčín',
        'Zvolen',
        'Poprad',
      ],
    },
  }

  return values
}
