<script setup lang="ts">
import IndexHeroSearch from '../index/hero/IndexHeroSearch.vue'
import ErrorHeading from './ErrorHeading.vue'
import Container from '~/components/layout/Container.vue'

const error = useError()
</script>

<template>
  <div class="c-error-main">
    <div class="c-error-main__bg">
      <NuxtImg
        src="/images/pages/error/hero-bg.jpg"
        width="1920"
        height="980"
      />
    </div>

    <Container class="c-error-main__container">
      <ErrorHeading />

      <template v-if="error && error.statusCode === 404">
        <hr class="c-error-main__divider" />

        <p class="c-error-main__try-search">
          {{ $t('error.trySearch') }}
        </p>

        <IndexHeroSearch :is-on-error-page="true" />
      </template>
    </Container>
  </div>
</template>

<style lang="scss" scoped>
@use '@inkline/inkline/css/mixins' as *;

:deep(.c-index-hero-search__box) {
  border-radius: 6px;
}

.c-error-main {
  position: relative;
  min-height: calc(100svh + 10px);
  padding: calc(var(--header-height) + 80px) 0 100px;
  margin-top: calc((var(--header-height) + 10px) * -1);
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  overflow: hidden;

  @include breakpoint-down('md') {
    min-height: 540px;
    padding: calc(var(--header-height) + 60px) 0 90px;
    margin-bottom: 65px;
  }

  @include breakpoint-down('sm') {
    padding: calc(var(--header-height) + 40px) 0 40px;
  }

  &__bg {
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: var(--color-primaryDark);

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__container {
    z-index: 2;
    max-width: 920px;
    display: flex;
    flex-direction: column;
    place-content: center;
  }

  &__divider {
    width: 100%;
    max-width: 420px;
    margin: 40px auto;
    border-color: hsla(
      var(--color-white-h),
      var(--color-white-s),
      var(--color-white-l),
      0.2
    );
  }

  &__try-search {
    font-size: 20px;
    font-weight: 500;
    margin: 0;
    color: var(--color-white);
    text-align: center;
    margin-bottom: 20px;
  }
}
</style>
