<script setup lang="ts">
const error = useError()

const isDev = process.dev
</script>

<template>
  <div v-if="error" class="c-error-heading">
    <h1 class="c-error-heading__title">
      <strong>{{ error.statusCode }}</strong>
    </h1>

    <div class="c-error-heading__message">
      <template v-if="error.statusMessage">{{ error.statusMessage }}</template>
      <template v-else>
        {{ $t('error.default') }}
        <br />
        {{ $t('error.tryAgainLater') }}
      </template>
    </div>

    <div v-if="isDev" class="c-error-heading__dev">
      <pre v-html="error.message" />
      <pre v-html="error.stack" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@inkline/inkline/css/mixins' as *;

.c-error-heading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: var(--color-light);
  gap: 20px;

  &__title {
    font-size: 200px;
    line-height: 0.94;
    margin: 0;

    :deep(strong) {
      font-weight: 500;
      color: var(--color-secondary);
      display: inline-block;
    }

    @include breakpoint-down('md') {
      font-size: 160px;
    }
    @include breakpoint-down('sm') {
      font-size: 140px;
    }
    @include breakpoint-down('xs') {
      font-size: 120px;
    }
  }

  &__message {
    font-size: 26px;
    line-height: 1.1;
    margin: 0;

    strong {
      display: inline-block;
    }

    @include breakpoint-down('md') {
      font-size: 24px;
    }
    @include breakpoint-down('sm') {
      font-size: 22px;
    }
    @include breakpoint-down('xs') {
      font-size: 20px;
    }
  }

  &__dev {
    text-align: left;
    margin-top: 50px;
  }
}
</style>
