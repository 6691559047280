
import * as ipxRuntime$i8Wo83IxfS from '/opt/atlassian/pipelines/agent/build/node_modules/@nuxt/image/dist/runtime/providers/ipx'

export const imageOptions = {
  "screens": {
    "xs": 359.99,
    "sm": 575.99,
    "md": 767.99,
    "lg": 991.99,
    "xl": 1199.99,
    "xxl": 1399.99,
    "2xl": 1399.99
  },
  "presets": {},
  "provider": "ipx",
  "domains": [],
  "alias": {},
  "densities": [
    1,
    2,
    1,
    2
  ],
  "format": [
    "avif",
    "webp",
    "webp"
  ],
  "quality": 92
}

imageOptions.providers = {
  ['ipx']: { provider: ipxRuntime$i8Wo83IxfS, defaults: {} }
}
        