<script setup lang="ts">
import { usePrivacyPolicyHref } from '~/composables/usePrivacyPolicyHref'

const consentHref = usePrivacyPolicyHref()
</script>

<template>
  <i18n-t keypath="forms.consent.base" tag="div" scope="global">
    <template #_0>
      <a
        :href="consentHref"
        target="_blank"
        class="c-link c-link--underlined"
        >{{ $t('forms.consent.0') }}</a
      >
    </template>
  </i18n-t>
</template>
