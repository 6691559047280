import CalibreWebRegular from '~/assets/fonts/calibre/calibre-web-regular.woff2?url'
import CalibreWebMedium from '~/assets/fonts/calibre/calibre-web-medium.woff2?url'
import CalibreWebSemibold from '~/assets/fonts/calibre/calibre-web-semibold.woff2?url'
import CalibreWebBold from '~/assets/fonts/calibre/calibre-web-bold.woff2?url'
import FinancierDisplayWebRegular from '~/assets/fonts/financier_display/financier-display-web-regular.woff2?url'
import FinancierDisplayWebRegularItalic from '~/assets/fonts/financier_display/financier-display-web-regular-italic.woff2?url'
import FinancierDisplayWebMedium from '~/assets/fonts/financier_display/financier-display-web-medium.woff2?url'
import FinancierDisplayWebMediumItalic from '~/assets/fonts/financier_display/financier-display-web-medium-italic.woff2?url'
import FinancierDisplayWebSemibold from '~/assets/fonts/financier_display/financier-display-web-semibold.woff2?url'
import FinancierDisplayWebSemiboldItalic from '~/assets/fonts/financier_display/financier-display-web-semibold-italic.woff2?url'

export default function useFontsPreload() {
  const fonts = [
    CalibreWebRegular,
    CalibreWebMedium,
    CalibreWebSemibold,
    CalibreWebBold,
    FinancierDisplayWebRegular,
    // FinancierDisplayWebRegularItalic,
    FinancierDisplayWebMedium,
    // FinancierDisplayWebMediumItalic,
    FinancierDisplayWebSemibold,
    // FinancierDisplayWebSemiboldItalic,
  ]

  useHead({
    link: fonts.map((font) => ({
      href: font,
      rel: 'preload',
      as: 'font',
      type: 'font/woff2',
      crossorigin: 'anonymous',
    })),
  })
}
