import { defineStore } from 'pinia'

export function getModalStore(name: string) {
  return defineStore(`${name}-store`, () => {
    const modalState = useModalState({
      modalId: name,
    })

    return {
      id: modalState.id,
      isOpened: modalState.isOpened,
      open: modalState.open,
      close: modalState.close,
      onOpen: modalState.onOpen,
      onClose: modalState.onClose,
    }
  })
}
