import { defineStore } from 'pinia'
import type { EstateDetailInterface } from '~/types/api/EstateDetail'

export default defineStore('estateDetailDataStore', () => {
  const data = ref<EstateDetailInterface | null>(null)

  function setData(estateData: EstateDetailInterface | null) {
    data.value = estateData
  }

  function clearData() {
    data.value = null
  }

  return {
    data,
    setData,
    clearData,
  }
})
