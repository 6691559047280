<script setup lang="ts">
import type { useIndexSearch } from '~/composables/index/useIndexSearch'

const props = defineProps<{
  indexSearch: ReturnType<typeof useIndexSearch>
}>()
</script>

<template>
  <div class="c-index-hero-search-nav">
    <IButton
      v-for="navItem in indexSearch.navItems"
      :key="navItem.id"
      :class="[
        indexSearch.activeNavItemId.value === navItem.id ? '-active' : '',
      ]"
      color="light"
      @click="props.indexSearch.setActiveNavItemId(navItem.id)"
    >
      <div :data-text="navItem.label">
        <span>{{ navItem.label }}</span>
      </div>
    </IButton>
  </div>
</template>

<style lang="scss" scoped>
@use '@inkline/inkline/css/mixins' as *;

.c-index-hero-search-nav {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, auto);
  gap: 24px;

  @include breakpoint-down('sm') {
    gap: 0;
    padding: 8px 10px;
    border-bottom: 1px solid var(--color-solid-grey);
  }

  :deep(.button) {
    --button--background: var(--color-light);
    --button--border-width: 0 0 2px 0;
    --button--border-color: var(--color-light);
    --button--font-weight: 400;
    --button--padding-top: 4px;
    --button--padding-bottom: 20px;

    --button--active--background: var(--color-light);
    --button--active--color: var(--color-primary);
    --button--active--border-color: var(--color-primary);
    --button--active--font-weight: 600;

    --button--hover--background: var(--color-light);
    --button--hover--color: var(--color-primary);

    min-width: 0;
    min-height: 0;
    white-space: normal;

    @include breakpoint-down('sm') {
      --button--border-width: 0;
      --button--padding-top: 5px;
      --button--padding-bottom: 5px;
      --button--padding-left: 8px;
      --button--padding-right: 8px;
      --button--font-size: 14px;

      --button--active--background: var(--color-primary);
      --button--active--color: var(--color-light);
      --button--active--font-weight: 500;

      min-height: 38px;
      border-radius: 4px;
    }

    [data-text] {
      display: grid;
      place-items: center;

      &::before,
      > * {
        grid-area: 1 / 1 / 2 / 2;
      }

      &::before {
        content: attr(data-text);
        font-weight: 600;
        pointer-events: none;
        user-select: none;
        visibility: hidden;
      }
    }
  }
}
</style>
