export const getHreflangLinks = (
  locale: string,
  skUrlVersion: string,
  enUrlVersion: string
) => {
  const {
    public: { BASE_APP_URL },
  } = useRuntimeConfig()
  if (locale === 'en') {
    const fullSkUrl = BASE_APP_URL + skUrlVersion

    return [
      {
        key: 'langUrl',
        rel: 'alternate',
        hreflang: 'sk',
        href: fullSkUrl,
      },
      {
        key: 'defaultUrl',
        rel: 'alternate',
        hreflang: 'x-default',
        href: fullSkUrl,
      },
    ]
  } else {
    const fullEnUrl = BASE_APP_URL + enUrlVersion

    return [
      {
        key: 'langUrl',
        rel: 'alternate',
        hreflang: 'en',
        href: fullEnUrl,
      },
      {
        key: 'defaultUrl',
        rel: 'alternate',
        hreflang: 'x-default',
        href: BASE_APP_URL + skUrlVersion,
      },
    ]
  }
}
