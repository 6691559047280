<script setup lang="ts">
import { IModal } from '@inkline/inkline'
import Icon from '~/components/global/Icon.vue'
import QuickOfferForm from '~/components/forms/QuickOfferForm.vue'
import useQuickOfferModalStore from '~/stores/modals/useQuickOfferModal'

const quickOfferModalStore = useQuickOfferModalStore()
</script>

<template>
  <IModal
    :hide-on-click-outside="false"
    :name="quickOfferModalStore.id"
    :model-value="quickOfferModalStore.isOpened"
    size="lg"
    @close="quickOfferModalStore.onClose()"
    @open="quickOfferModalStore.onOpen()"
  >
    <template #header>{{ $t('modals.quickOffer.title') }}</template>
    <template #close><Icon name="close" /></template>

    <div class="c-modal-body">
      <p
        class="c-modal-body__text"
        v-html="
          $t('modals.quickOffer.text', {
            _0: '<strong>',
            _1: '</strong>',
          })
        "
      />

      <QuickOfferForm />
    </div>

    <template #footer>
      <div class="c-footer">
        <div class="c-footer__title">
          {{ $t('forms.quickOffer.footerTitle') }}
        </div>

        <div class="c-footer__availability">
          <span>{{ getTextConfiguration('general.contact_days_short') }}</span>
          <strong>{{ getTextConfiguration('general.contact_hours') }}</strong>
        </div>

        <div class="c-footer__phone">
          <a
            class="c-link c-link--secondary"
            :href="`tel:${getTextConfiguration('general.phone')}`"
            >{{ getTextConfiguration('general.phone') }}</a
          >
        </div>
      </div>
    </template>
  </IModal>
</template>

<style lang="scss" scoped>
@use '@inkline/inkline/css/mixins' as *;

:deep(.modal) {
  --modal--header--padding: 64px 115px 20px;
  --modal--footer--padding: 64px 60px 40px;

  @include breakpoint-down('sm') {
    --modal--header--padding: 36px 40px 12px;
    --modal--footer--padding: 36px 40px 24px;
  }
}

.c-modal-body {
  max-width: 540px;
  margin: auto;

  &__text {
    text-align: center;
    line-height: 1.28;
    margin-bottom: 32px;
    padding-bottom: 32px;
    border-bottom: 1px solid var(--color-border);
  }
}

.c-footer {
  max-width: 540px;
  margin: auto;
  display: grid;
  gap: 0 48px;
  grid-template-columns: 1fr auto;
  padding-top: 32px;
  border-top: 1px solid var(--color-border);

  @include breakpoint-down('sm') {
    grid-template-columns: 1fr;
  }

  &__title {
    grid-row: 1 / span 2;
    line-height: 1.28;

    @include breakpoint-down('sm') {
      grid-row: 1;
      margin-bottom: 20px;
    }
  }

  &__availability {
    display: flex;
    gap: 8px;
    min-width: 0;
    white-space: nowrap;
  }

  &__phone {
    min-width: 0;
    white-space: nowrap;

    .c-link {
      font-family: var(--font-family-secondary-base);
      font-size: 32px;
      line-height: 1.2;
      font-weight: 500;
    }
  }
}
</style>
