import getEstateTrackingData from '~/utils/tracking/getEstateTrackingData'

export default function () {
  const route = useRoute()
  const parsedName = route?.name?.toString()?.split?.('___')?.[0] || ''

  const pageTypes = {
    'index': 'homepage',
    'office-slug-id': 'listing-kancelarske-priestory',
    'warehouse-slug-id': 'listing-sklady-haly-a-pozemky',
    'retail-slug-id': 'listing-obchodne-priestory',
  } as Record<string, string>

  return {
    page_type: pageTypes?.[parsedName] ?? 'other',
    ...getEstateTrackingData(),
  }
}
