<script setup lang="ts">
import QuickOfferModal from './components/modals/QuickOfferModal.vue'
import useFontsPreload from './composables/preload/useFontsPreload'
import useFavoritesStore from './stores/useFavoritesStore'

const { locale } = useI18n()

useFontsPreload()

const router = useRouter()

watchEffect(() => {
  const skUrl = useLocaleLink(
    { path: router.currentRoute.value.fullPath },
    'sk'
  )
  const enUrl = useLocaleLink(
    { path: router.currentRoute.value.fullPath },
    'en'
  )

  const currentLinks = getHreflangLinks(
    locale.value,
    skUrl?.fullPath as string,
    enUrl?.fullPath as string
  )

  useHead({
    link: currentLinks,
  })
})

defineOgImage({
  component: 'root',
  url: `/images/share/share-image-${locale.value}.png`,
  width: 1200,
  height: 630,
})

onMounted(() => {
  const favoritesStore = useFavoritesStore()

  favoritesStore.init()
})
</script>

<template>
  <div id="app-root">
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>

    <QuickOfferModal />
  </div>
</template>
