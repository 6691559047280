import { useToast, type ToastOptions } from '@inkline/inkline'

export const showToast = (options: Partial<ToastOptions>) => {
  const toast = useToast()

  const defaultDuration =
    !options?.color || options?.color === 'success' ? 10000 : 20000

  let defaultPosition = 'top-right'
  if (typeof window !== 'undefined') {
    if (window.matchMedia('screen and (max-width: 768px)').matches) {
      defaultPosition = 'bottom'
    }
  }

  toast.show({
    ...{
      color: 'success',
      duration: defaultDuration,
      dismissible: true,
      position: defaultPosition,
    },
    ...options,
  })
}
