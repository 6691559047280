import type { EstateType } from '~/types/EstateType'

export default function getEstateBaseRouteName(estateType: EstateType) {
  let routeName = 'offices'

  if (estateType === 'warehouse') routeName = 'warehouses'
  if (estateType === 'retail') routeName = 'retail'

  return routeName
}
