<script setup lang="ts">
import IndexHeroSearchForm from './IndexHeroSearchForm.vue'
import IndexHeroSearchNav from './IndexHeroSearchNav.vue'
import IndexHeroSearchLinks from './IndexHeroSearchLinks.vue'
import { useIndexSearch } from '~/composables/index/useIndexSearch'

defineProps<{
  isOnErrorPage?: boolean
}>()

const indexSearch = useIndexSearch()
</script>

<template>
  <div class="c-index-hero-search">
    <div class="c-index-hero-search__box">
      <IndexHeroSearchNav :index-search="indexSearch" />
      <IndexHeroSearchForm
        :index-search="indexSearch"
        :is-on-error-page="isOnErrorPage"
      />
    </div>

    <IndexHeroSearchLinks :index-search="indexSearch" />
  </div>
</template>

<style lang="scss" scoped>
@use '@inkline/inkline/css/mixins' as *;

.c-index-hero-search {
  display: flex;
  flex-direction: column;
  gap: 16px;
  place-items: center;
  width: 100%;
  max-width: 740px;
  margin: 0 auto;

  @include breakpoint-down('md') {
    max-width: 680px;
  }

  &__box {
    width: 100%;
    background-color: var(--color-light);
    padding: 24px 56px 32px;
    gap: 16px;
    display: flex;
    flex-direction: column;

    @include breakpoint-down('md') {
      padding: 24px 32px 32px;
    }
    @include breakpoint-down('sm') {
      padding: 0;
      border-radius: 4px;
      gap: 0;
    }
  }
}
</style>
